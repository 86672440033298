<template>
  <carousel
  class="carousel"
  ref="carousel"
  :perPage="1"
  :scrollPerPage="false"
  :paginationEnabled="false"
  :navigationEnabled="true"
  :loop="true"
  :navigationNextLabel="null"
  :navigationPrevLabel="null"
  :centerMode="true"
  >
    <slide
    v-for="(member, n) in members"
    :key="n"
    class="slide"
    >
      <router-link
      :to="`/members/${member.url}`"
      >
        <div class="img">
          <img :src="`/member_photos/${member.url}.jpg`" alt="">
        </div>
      </router-link>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import memberData from '../assets/json/members.json'

export default {
  components: {
    Carousel,
    Slide
  },
  data: ()=>{
    return {
      members: memberData,
    }
  },
  mounted(){
    setTimeout(() => {
      this.$refs.carousel.goToPage(1)
    }, 100);
  }
}
</script>

<style lang="scss">
@import '../assets/css/common.scss';

.carousel {
  width: 30rem;
  margin:  0 auto;
  @include SP {
    width: 22rem;
  }
  .VueCarousel-wrapper {
    overflow: visible;
  }
  .slide {
    position: relative;
    width: 30rem;
    height: 36rem;
    padding: 1rem;
    overflow: hidden;
    @include SP {
      width: 22rem;
      height: 25rem;
    }
    .img {
      width: 28rem;
      height: 34rem;
      background: #e0e0e0;
      @include SP {
        width: 20.5rem;
        height: 23.5rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      position: absolute;
      bottom: 2rem;
      right: 1.4rem;
      margin: auto;
      color: #fff;
    }
    .nameBg {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 38rem;
      height: 16rem;
      background: #000;
      transform-origin: bottom right;
      transform: rotate(-28deg);
      @include SP {
      }
    }
  }
  .VueCarousel-navigation {
    .VueCarousel-navigation-button {
      &:focus {
        outline: none;
      }
      &::after {
        content: "";
        display: block;
        width: 5.5rem;
        height: 5.5rem;
        background: url('../assets/img/members/slide_arrow.png') center center / contain no-repeat;
      }
      &.VueCarousel-navigation-prev {
        left: -30rem;
      }
      &.VueCarousel-navigation-next {
        right: -30rem;
        &::after {
          transform: scaleX(-1);
        }
      }
    }
  }
}
</style>