var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('header',[_c('div',{staticClass:"bgWrapper"},[_c('div',{ref:"mv"})]),_vm._m(0)]),_c('main',[_c('section',{staticClass:"whatWeThink",attrs:{"id":"philosophy"}},[_c('div',{staticClass:"wrapper"},[_c('HomeH2',{attrs:{"to":"/philosophy"}},[_c('img',{attrs:{"src":require("../assets/img/text/what_we_think.svg"),"alt":"what we think"}})])],1),_vm._m(1)]),_c('section',{staticClass:"services",attrs:{"id":"services"}},[_c('div',{staticClass:"wrapper"},[_c('HomeH2',[_c('img',{attrs:{"src":require("../assets/img/text/services.svg"),"alt":"services"}})])],1),_c('ul',[_c('li',{staticClass:"zenow"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"content"},[_vm._m(2),_c('span',{staticClass:"pc"},[_c('router-link',{staticClass:"more",attrs:{"to":"/zenow"}},[_c('Button',{staticClass:"black"},[_vm._v(" more... ")])],1)],1),_vm._m(3)])]),_vm._m(4)]),_c('li',{staticClass:"calten"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"content"},[_vm._m(5),_c('Button',{staticClass:"disabled"},[_vm._v(" 開発中 ")]),_vm._m(6)],1)]),_vm._m(7)])])]),_c('section',{staticClass:"members",attrs:{"id":"members"}},[_c('div',{staticClass:"wrapper"},[_c('HomeH2',{attrs:{"to":"/members/kano_takumi"}},[_c('img',{attrs:{"src":require("../assets/img/text/members.svg"),"alt":"members"}})])],1),_c('Members')],1),_c('section',{staticClass:"contact",attrs:{"id":"contact"}},[_c('div',{staticClass:"bg"},[_c('div',{staticClass:"wrapper"},[_vm._m(8),_c('ContactForm')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('h1',[_c('img',{attrs:{"src":require("../assets/img/mv_title.svg"),"alt":""}}),_c('span',{staticClass:"sub"},[_vm._v(" 進みやすい社会 ")])]),_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require("../assets/img/header_arrow.svg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('p',[_vm._v(" “進みたい”という意思や熱量があっても、 “上手く進めない” ことがある世の中だ。"),_c('br'),_vm._v(" 僕たちは、そういった障害を一つひとつ"),_c('br',{staticClass:"sp"}),_vm._v("取り除いていき、"),_c('br',{staticClass:"sp"}),_vm._v("「進みやすい社会」を創る。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('img',{attrs:{"src":require("@/assets/img/zenow_logo.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 高校生以上のスポーツ選手を対象に、"),_c('br'),_vm._v(" 目標の設定・管理をサポートするアプリ。"),_c('br'),_vm._v(" 選手の思考過程を指導者にリアルタイムで共有し、"),_c('br'),_vm._v(" コーチングの質の向上・時間短縮に貢献します。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/img/services_zenow.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('img',{attrs:{"src":require("../assets/img/calten_logo.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 指導する相手の情報を残すことは、"),_c('br',{staticClass:"pc"}),_vm._v(" 次の指導の質を高め、相手との信頼の構築に繋がります。"),_c('br',{staticClass:"pc"}),_vm._v(" 短期的なその場限りの指導から、長期的な意図のある指導へ。"),_c('br',{staticClass:"pc"}),_vm._v(" 指導に一貫性を持たせる、パーソナルトレーナー向け記録アプリ。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/img/services_calten.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('img',{attrs:{"src":require("../assets/img/text/contact.svg"),"alt":"contact"}})])
}]

export { render, staticRenderFns }