<template>
  <div class="form">
    <div class="column left">
      <div class="row">
        <label>
          貴組織名
        </label>
        <input type="text" v-model="orgnization">
      </div>
      <div class="row">
        <label>
          ご担当者名
        </label>
        <input type="text" v-model="name">
      </div>
      <div class="row">
        <label>
          メールアドレス
        </label>
        <input type="text" v-model="email">
      </div>
    </div>
    <div class="column right">
      <div class="row">
        <label>
          お問い合わせ内容
        </label>
        <textarea v-model="content"></textarea>
      </div>
    </div>
    <Button class="submit black" @click.native="post">
      submit
    </Button>
    <div class="sending" v-if="sending">
      送信しています…
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Button from './borderedwhitebutton.vue'
export default {
  components: {
    Button,
  },
  data(){
    return {
      orgnization: '',
      name: '',
      email: '',
      content: '',
      sending: false,
    }
  },
  mounted(){
  },
  methods: {
    async post(){
      const endpoint = process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/api/addContactFormRequest'
      : 'https://us-central1-norths-fade2.cloudfunctions.net/api/addContactFormRequest';
      if(!(this.orgnization&&this.name&&this.email&&this.content)){
        alert('フォームに不備があります。');
        return
      }
      this.sending = true;
      await axios.post(
        endpoint,
        {
          orgnization: this.orgnization,
          name: this.name,
          email: this.email,
          content: this.content,
        },
        {
          fields: {
            orgnization: {
              stringValue: this.orgnization
            },
            name: {
              stringValue: this.name
            },
            email: {
              stringValue: this.email
            },
            content: {
              stringValue: this.content
            },
          }
        }
      );
      this.$router.push('/contactform-sent')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.form {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  .row {
    font-size: 0;
    input, textarea {
      display: block;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      font-size: 1.6rem;
      padding: 1.5rem 1.4rem;
      border: none;
      outline: none;
      line-height: 2;
    }
    textarea {
      height: 100%;
      @include SP {
        height: 13rem;
      }
    }
    label {
      font-size: 1.3rem;
      line-height: 1;
    }
    &+.row {
      @include SP {
        margin-top: 1.5rem;
      }
    }
  }
  .column {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    height: 30rem;
    @include SP {
      height: auto;
    }
    &.left {
      width: 38.2rem;
    }
    &.right {
      width: 51rem;
      @include SP {
        margin-top: 1.5rem;
      }
      .row {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
      }
    }
  }
  .submit {
    margin: 3.8rem 0 0 auto;
    @include SP {
      margin: 4rem auto 0;
    }
  }
  .sending {
    width: 100%;
    text-align: right;
  }
}
</style>