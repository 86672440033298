import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      'title': 'norths-進みやすい社会',
      'desc': '“進みたい”という意思や熱量があっても、 “上手く進めない” ことがある世の中だ。僕たちは、そういった障害を一つひとつ取り除いていき、「進みやすい社会」を創る。',
    }
  },
  {
    path: '/philosophy',
    name: 'Philosophy',
    component: () => import('../views/philosophy.vue'),
    meta:{
      'title': 'philosophy | norths-進みやすい社会',
      'desc': '',
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/company.vue'),
    meta:{
      'title': 'company profile | norths-進みやすい社会',
      'desc': '',
    }
  },
  {
    path: '/members/:member',
    name: 'Members',
    component: () => import('../views/members.vue'),
    meta:{
      'title': 'members | norths-進みやすい社会',
      'desc': '',
    }
  },
  {
    path: '/contactform-sent',
    name: 'ContactformSent',
    component: () => import('../views/contactform-sent.vue'),
    meta:{
      'title': 'お問い合わせありがとうございます。 | norths-進みやすい社会',
      'desc': '',
    }
  },
  {
    path: '/zenow',
    name: 'Zenow',
    component: () => import('../views/lp/zenow.vue'),
    meta:{
      isLandingPage: true,
      'title': 'zenow｜選手の目標設定と自己管理をサポート',
      'desc': '選手全員の目標と、思考の過程をリアルタイムに確認。一人ひとりの選手に寄り添った支援を可能に。',
      'favicon': '/favicon_zenow.ico',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async (to, from, savedPosition) => {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'

    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (from, to) => {
      return new Promise((resolve, reject) => {
        if(to.path===from.path){
          resolve(document.querySelector(to.hash));
        }
        setTimeout(() => {
          resolve(document.querySelector(to.hash));
        }, 450);
      },);
    }

    if (to.hash) {
      const el = await findEl(from, to);
      return window.scrollBy({
        top: el.getBoundingClientRect().top,
      });
    }
    if (to.path == '/') {
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        return window.scrollTo(0, 0);
      }
    }
    return { x: 0, y: 0 };
  }
})

export default router
