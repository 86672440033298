<template>
  <div id="app">
    <GlobalNav v-if="!isLandingPage"/>
    <transition name="route">
      <router-view/>
    </transition>
    <Footer v-if="!isLandingPage" />
  </div>
</template>

<script>
import GlobalNav from '@/components/globalnav.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    GlobalNav,
    Footer,
  },
  data(){
    return {
    }
  },
  methods : {
    setMeta(route){
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      let desc = route.meta.desc;
      if(route.meta.favicon){
        console.log(route.meta.favicon);
        document.querySelector("[rel='icon']").setAttribute('href', route.meta.favicon)
      }else{
        document.querySelector("[rel='icon']").setAttribute('href', '/favicon.svg')
      }
      document.querySelector("meta[name='description']").setAttribute(
        'content',
        desc?desc:'',
      )
    }
  },
  computed: {
    isLandingPage(){
      return this.$route.meta.isLandingPage;
    }
  },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    '$route':{
      deep: true,
      handler(route, from) {
        this.setMeta(route);
      },
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/common.scss';
@import url('./assets/css/reset.css');

.route-enter-active:not(.lp), .route-leave-active {
  transition: .4s;
  width: 100%;
}
.route-enter, .route-leave-to {
  opacity: 0;
}
.route-enter {
  transform: translateX(50px);
}
.route-leave-to {
  transform: translateX(-50px);
}
</style>
