<template>
  <div class="home">
    <header>
      <div class="bgWrapper">
        <div ref="mv"></div>
      </div>
      <div class="wrapper">
        <h1>
          <img src="../assets/img/mv_title.svg" alt="">
          <span class="sub">
            進みやすい社会
          </span>
        </h1>
        <div class="arrow">
          <img src="../assets/img/header_arrow.svg" alt="">
        </div>
      </div>
    </header>
    <main>
      <section class="whatWeThink" id="philosophy">
        <div class="wrapper">
          <HomeH2
          to="/philosophy"
          >
            <img
            src="../assets/img/text/what_we_think.svg"
            alt="what we think">
          </HomeH2>
        </div>
        <div class="description">
          <p>
            “進みたい”という意思や熱量があっても、 “上手く進めない” ことがある世の中だ。<br>
            僕たちは、そういった障害を一つひとつ<br class="sp">取り除いていき、<br class="sp">「進みやすい社会」を創る。
          </p>
        </div>
      </section>
      <section class="services" id="services">
        <div class="wrapper">
          <HomeH2>
            <img src="../assets/img/text/services.svg" alt="services">
          </HomeH2>
        </div>
        <ul>
          <li class="zenow">
            <div class="text">
              <div class="content">
                <h3>
                  <img src="@/assets/img/zenow_logo.svg" alt="">
                </h3>
                <span class="pc">
                  <router-link class="more" to="/zenow">
                    <Button class="black">
                      more...
                    </Button>
                  </router-link>
                </span>
                <p>
                  高校生以上のスポーツ選手を対象に、<br>
                  目標の設定・管理をサポートするアプリ。<br>
                  選手の思考過程を指導者にリアルタイムで共有し、<br>
                  コーチングの質の向上・時間短縮に貢献します。
                </p>
              </div>
            </div>
            <div class="img">
              <img src="../assets/img/services_zenow.png" alt="">
            </div>
          </li>
          <li class="calten">
            <div class="text">
              <div class="content">
                <h3>
                  <!-- <router-link class="more" to="/"> -->
                    <img src="../assets/img/calten_logo.svg" alt="">
                  <!-- </router-link> -->
                </h3>
                <!-- <router-link class="more pc" to="/"> -->
                <Button class="disabled">
                  開発中
                </Button>
                <!-- </router-link> -->
                <p>
                  指導する相手の情報を残すことは、<br class="pc">
                  次の指導の質を高め、相手との信頼の構築に繋がります。<br class="pc">
                  短期的なその場限りの指導から、長期的な意図のある指導へ。<br class="pc">
                  指導に一貫性を持たせる、パーソナルトレーナー向け記録アプリ。
                </p>
              </div>
            </div>
            <div class="img">
              <img src="../assets/img/services_calten.jpg" alt="">
            </div>
          </li>
        </ul>
      </section>
      <section class="members" id="members">
        <div class="wrapper">
          <HomeH2
          to="/members/kano_takumi"
          >
            <img src="../assets/img/text/members.svg" alt="members">
          </HomeH2>
        </div>
        <Members/>
      </section>
      <section class="contact" id="contact">
        <div class="bg">
          <div class="wrapper">
            <h2>
              <img src="../assets/img/text/contact.svg" alt="contact">
            </h2>
            <ContactForm/>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '@/assets/json/mv.json';

import HomeH2 from '../components/home_h2.vue';
import Button from '../components/borderedwhitebutton.vue'
import Members from '../components/members.vue'
import ContactForm from '../components/contactform.vue'

export default {
  components: {
    HomeH2,
    Button,
    Members,
    ContactForm,
  },
  data: ()=>{
    return {
      player: null,
    }
  },
  mounted() {
    this.player = lottie.loadAnimation({
      container: this.$refs.mv,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData,
    })
    this.player.playSegments([1,402],true);
    this.player.addEventListener('complete',()=>{
      this.player.playSegments([192,402],true);
      this.player.play();
    });
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #888;
  color: #fff;
  .wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 60.2rem;
      @include SP {
        width: 23.3rem;
      }
      .sub {
        display: block;
        margin-top: 5rem;
        font-size: 3.8rem;
        @include SP {
          font-size: 2rem;
          margin-top: 2.4rem;
        }
      }
    }
    .arrow {
      position: absolute;
      bottom: 6.5rem;
      right: 0;
      left: 0;
      margin: auto;
      width: 2.2rem;
      height: 11rem;
      @include SP {
        width: 1.3rem;
        height: auto;
        bottom: 4rem;
      }
    }
  }
  .bgWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    &>div {
      min-width: 1920px;
      min-height: 1280px;
    }
  }
}

main {
  position: relative;
  z-index: 45;
  margin-top: 100vh;
  padding: 20rem 0 0;
  background: #fff;
  overflow-x: hidden;
  @include SP {
    padding: 15rem 0 0;
  }
  section {
    h2 {
      margin-bottom: 3.2rem;
      img {
        height: 8.3rem;
        @include SP {
          height: 3.8rem;
        }
      }
    }
  }
  .whatWeThink {
    @include PC {
      transform: rotate(-5deg);
    }
    .homeH2 {
      @include SP {
        transform: rotate(-5deg);
        transform-origin: left bottom;
      }
    }
    .description {
      background: #F2F2F2;
      @include PC {
        width: calc(100% - ((100% - 94rem) / 2) + 2rem);
        margin-left: -2rem;
        padding: 6rem;
        padding-left: calc(((100% - 94rem) / 2) + 10rem);
      }
      @include SP {
        width: 32rem;
        margin-left: -2rem;
        padding: 1.6rem 0;
        transform: rotate(-5deg);
        transform-origin: left bottom;
      }
      p {
        text-align: center;
        font-size: 2rem;
        line-height: 2.5;
        @include SP {
          width: 33rem;
          margin-left: 8rem;
          text-align: left;
          font-size: 1.7rem;
        }
      }
    }
  }
  .services {
    margin-top: 10rem;
    h2 {
      text-align: right;
      justify-content: flex-end;
      margin-bottom: 0;
      @include PC {
        transform: rotate(5deg);
      }
      @include SP {
        transform: rotate(5deg);
        margin-bottom: 3rem;
      }
    }
    ul {
      &>li {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row-reverse;
        height: 38rem;
        background: #F2F2F2;
        @include PC {
          width: calc(100% - ((100% - 94rem) / 2) );
        }
        @include SP {
          width: 100%;
          height: 24rem;
          padding: 3rem 0;
          flex-direction: column;
        }
        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 0 8rem 0 5.4rem;
          @include SP {
            width: 33rem;
            min-width: 33rem;
            padding: 0;
          }
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            h3 {
              @include SP {
                width: 100%;
              }
              a {
                @include SP {
                  display: inline-flex;
                  justify-content: flex-start;
                  align-items: center;
                }
                &::after {
                  @include SP {
                    content: "";
                    display: inline-block;
                    width: .8rem;
                    height: 1.4rem;
                    margin-left: .8rem;
                    background: url('../assets/img/arrow_blue_right.svg') center center / contain no-repeat;
                  }
                }
              }
            }
            p {
              width: 100%;
              font-size: 1.8rem;
              line-height: 2.3;
              margin-top: 2.4rem;
              @include SP {
                margin-top: .8rem;
                font-size: 1.3rem;
                line-height: 2.5rem;
              }
            }
          }
        }
        .img {
          width: 45.2rem;
          height: 37.8rem;
          flex-shrink: 0;
          @include SP {
            width: 29.7rem;
            height: 24.9rem;
            margin-top: .8rem;
            margin-left: 18rem;
          }
          img {
            transform: translateY(3.8rem);
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include SP {
              transform: none;
            }
          }
        }
        &.zenow {
          transform: rotate(5deg);
          transform-origin: left top;
          @include SP {
            margin-left: -7rem;
          }
          .text {
            padding-right: 0;
            padding-left: 0;
            @include SP {
              margin-left: 10rem;
            }
            .content {
              @include PC {
                padding-right: 4rem;
                transform: translateX(6rem);
              }
            }
            h3 {
              img {
                width: 32.8rem;
                @include SP {
                  width: 10.8rem;
                }
              }
            }
          }
        }
        &.calten {
          flex-direction: row;
          margin-top: 6.8rem;
          margin-left: auto;
          margin-right: 0;
          transform: rotate(-5deg);
          transform-origin: right top;
          @include SP {
            flex-direction: column;
            margin-top: 30rem;
            margin-right: -7rem;
            margin-left: 4rem;
            padding-bottom: 5.2rem;
            height: 24rem;
            transform-origin: right bottom;
          }
          .text {
            margin-left: -4rem;
            padding-left: 10rem;
            padding-right: 4rem;
            @include SP {
              padding: 0;
              margin-left: 0;
            }
            .content {
              @include SP {
                width: 30rem;
              }
              h3 {
                @include SP {
                  width: auto;
                }
                img {
                  width: 26.6rem;
                  @include SP {
                    width: 14.8rem;
                  }
                }
              }
            }
          }
          .img {
            @include SP {
              margin: 0 0 0 -7rem;
            }
          }
        }
      }
    }
  }
  .members {
    margin-top: 26rem;
    @include PC {
      transform: rotate(5deg);
    }
    @include SP {
      margin-top: 40rem;
      transform: rotate(5deg);
    }
  }
  .contact {
    padding: 16rem 0 0;
    margin-top: 16rem;
    @include SP {
      padding: 13rem 0 0 0;
      margin-top: 13rem;
    }
    .bg {
      background: #F2F2F2;
      padding-bottom: 7.6rem;
    }
    h2 {
      font-size: 12rem;
      line-height: 1;
      transform: translateY(-.7em);
      margin-bottom: -2rem;
      @include SP {
        font-size: 5.6rem;
        margin-bottom: 0;
      }
    }
  }
}
</style>