<template>
  <h2 class="homeH2"
  >
    <template
    v-if="to"
    >
      <router-link
      @mouseover.native="play"
      @mouseleave.native="reverse"
      :to="to"
      >
        <slot/>
      </router-link>
      <div class="arrowWrapper">
        <Arrow
        ref="arrow"
        class="arrow"
        />
      </div>
    </template>
    <template v-else>
      <slot/>
    </template>
  </h2>
</template>

<script>
import Arrow from '../components/arrow.vue'
export default {
  name: 'Home',
  components: {
    Arrow,
  },
  props: {
    'to': String,
  },
  data:()=>{
    return {
      hover: false,
    }
  },
  methods: {
    play(){
      if(!this.$refs.arrow) return;
      this.$refs.arrow.play();
    },
    reverse(){
      if(!this.$refs.arrow) return;
      this.$refs.arrow.reverse();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.homeH2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  font-size: 11rem;
  @include SP {
    flex-direction: column;
    align-items: flex-start;
    font-size: 5.4rem;
  }
  a {
    padding-bottom: .2em;
  }
  .arrowWrapper {
    width: 11rem;
    height: 2.2rem;
    margin-left: 2.4rem;
    @include SP {
      margin: .8rem 0 0;
    }
    .arrow {
      width: 18rem;
      height: 100%;
    }
  }
}
</style>