<template>
  <div id="lottie" ref="lottie"></div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '@/assets/json/arrow.json';

export default {
  data: ()=>{
    return {
      player: null,
    }
  },
  mounted() {
    this.player = lottie.loadAnimation({
      container: this.$refs.lottie,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    })
  },
  methods: {
    play(){
      this.player.setDirection(1);
      this.player.play();
    },
    reverse(){
      this.player.setDirection(-1);
      this.player.play();
    }
  }
};
</script>

<style>
#lottie {
  font-size: 0;
}
</style>