<template>
  <nav id="global">
    <div class="right">
      <router-link to="/" @click.native="scrollTop">
        <img src="../assets/img/logo.svg" alt="">
      </router-link>
    </div>
    <div class="left">
      <div class="links"
      :class="{
        'closed': !opened,
      }">
        <ul @click="opened = true">
          <li>
            <router-link to="/philosophy">
              philosophy
            </router-link>
          </li>
          <li>
            <router-link to="/#services">
              services
            </router-link>
          </li>
          <li>
            <router-link to="/members/kano_takumi">
              members
            </router-link>
          </li>
          <li>
            <router-link to="/company">
              company
            </router-link>
          </li>
          <li>
            <router-link to="/#contact">
              contact
            </router-link>
          </li>
        </ul>
        <div class="close" v-if="opened" @click="opened = false">
          <img src="../assets/img/globalnav/close.svg" alt="">
        </div>
      </div>
    </div>
    <div class="menu"
    :class="{
      'opened': menuOpened,
    }"
    >
      <div class="menuButton" @click="menuOpened=!menuOpened">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="content">
        <ul class="links">
          <li>
            <router-link to="/philosophy" @click.native="menuOpened=false">
              philosophy
            </router-link>
          </li>
          <li>
            <router-link to="/#services" @click.native="menuOpened=false">
              services
            </router-link>
          </li>
          <li>
            <router-link to="/members/kano_takumi" @click.native="menuOpened=false">
              members
            </router-link>
          </li>
          <li>
            <router-link to="/company" @click.native="menuOpened=false">
              company
            </router-link>
          </li>
          <li>
            <router-link to="/#contact" @click.native="menuOpened=false">
              contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade">
      <div class="scrollTop" @click="scrollTop" v-if="scrollY > 100">
        <img src="../assets/img/scrolltop.png" alt="">
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  data: ()=>{
    return {
      scrollY: 0,
      opened: false,
      menuOpened: false,
    }
  },
  methods: {
    scrollTop(){
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        return window.scrollTo(0, 0);
      }
    }
  },
  mounted(){
    window.addEventListener('scroll',()=>{
      this.scrollY = window.scrollY;
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

#global {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.4rem;
  pointer-events: none;
  @include SP {
    height: 6.4rem;
  }
  &>div {
    pointer-events: all;
  }
  .right {
    a {
      cursor: pointer;
      img {
        width: 6.5rem;
        @include SP {
          width: 4.5rem;
        }
      }
    }
  }
  .left {
    position: relative;
    height: 100%;
    @include SP {
      display: none;
    }
    .links {
      position: absolute;
      top: 2.5rem;
      right: 2.4rem;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        font-family: "LiHei Pro",sans-serif;
        &>li {
          transform-origin: center;
          font-size: 2.2rem;
          text-align: right;
          line-height: 1.3;
          transition: .6s;
          a {
            display: block;
            transition: .6s;
            &:hover {
              text-decoration: underline;
            }
          }
          &+li {
            margin-top: 1.5rem;
          }
        }
      }
      .close {
        width: 1.4rem;
        height: 1.4rem;
        margin-top: 2.5rem;
        cursor: pointer;
      }
      &.closed {
        ul {
          cursor: pointer;
          &>li {
            pointer-events: none;
            &:nth-child(1) {
              transform: rotate(10deg);
            }
            &:nth-child(2) {
              transform: translateY(-3.2rem);
              a {
                transform: rotate(-6deg);
              }
            }
            &:nth-child(3) {
              transform: translateY(-6.5rem);
              a {
                transform: rotate(1deg);
              }
            }
            &:nth-child(4){
              transform: rotate(-26deg) translate(4rem,  -8.2rem);
            }
            &:nth-child(5) {
              transform: translateY(-12.2rem);
              a {
                transform: rotate(33deg);
              }
            }
          }
        }
      }
    }
  }
  .menu {
    @include PC {
      display: none;
    }
    .menuButton {
      @include PC {
        display: none;
      }
      position: relative;
      z-index: 150;
      display: flex;
      flex-direction: column;
      padding: 1rem 0 1rem 1rem;
      &>div {
        width: 2.7rem;
        height: .3rem;
        background: #000;
        display: block;
        transition: .4s;
        transform-origin: center left;
        &:nth-child(1){
          transform: rotate(2deg);
        }
        &:nth-child(2){
          transform: rotate(-4deg);
          margin-top: .8rem;
        }
        &:nth-child(3){
          margin-top: .5rem;
          transform: rotate(3deg);
        }
      }
    }
    &.opened > .menuButton {
      &>div {
        width: 3.4rem;
        &:nth-child(1){
          transform: rotate(39deg) translateY(0rem);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:nth-child(3){
          transform: rotate(-31deg) translateY(0rem);
        }
      }
    }
    .content {
      position: fixed;
      top: 0;
      right: 0;
      width: 28.5rem;
      height: 100%;
      background: #fff;
      transform: translateX(28.5rem);
      transition: .4s;
      padding: 13.2rem 7.4rem 0 0;
      &>.links {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        &>li {
          font-size: 2.8rem;
          line-height: 1;
          &+li {
            margin-top: 3rem;
          }
        }
      }
    }
    &.opened > .content {
      transform: translateX(0);
    }
  }
  .scrollTop {
    position: fixed;
    bottom: 2.4rem;
    right: 2.4rem;
    width: 5.5rem;
    height: 5.5rem;
    pointer-events: all;
    cursor: pointer;
  }
}
</style>